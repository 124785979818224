<template>
  <div class="item">
    <el-row>
     
      <el-col class="myAcount">
        <!--memberStoreType 1.平台  2.签约 无等级     -->
        <el-col :span="12" class="list"  v-if="info.memberStoreType==1" @click.native="JumpMemberLevel">
          <el-col class="acount">
            <span>账户名称：</span>
            <span class="name">
              {{ info.enterpriseName }}
            </span>
          </el-col>
          <el-col>
            <span>会员等级：</span>
            <span class="levelBg text-center">
              {{info.memberStoreLevel}}.{{memberLevelName}}
              <!-- {{info.memberLevelName}} -->
              <!-- 会员优化中 -->
            </span>
          </el-col>
          <el-col>
            <span>成长值：</span>
            <span class="GrownNum">
              {{ info.growthValue }}
              <!-- 会员优化中 -->
            </span>
          </el-col>
          <el-col>
            <span>我的客服：</span><span class="service">{{ info.customName }}
            </span>
            <span class="person">
              {{ info.customPhone }}
            </span>
          </el-col>
        </el-col>
        <el-col :span="12" class="list"  v-else>
          <!-- {{info.memberStoreType==2}} -->
          <el-col class="acount">
            <span>账户名称：</span>
            <span class="name">
              {{ info.enterpriseName }}
            </span>
          </el-col>
          <el-col class="GrownDiv">
            <span>会员等级：</span>
            <span class="levelBg text-center">
              签约会员
              <!-- {{info.memberLevelName}} -->
              
            </span>
          </el-col>
          <el-col>
            <span>我的客服：</span><span class="service">{{ info.customName }}
            </span>
            <span class="person">
              {{ info.customPhone }}
            </span>
          </el-col>
            <!-- <el-col>
              <span>区域经理：</span>
              <span class="service">{{ info.customName }}</span>
              <span class="person">{{ info.customPhone }}</span>
            </el-col> -->
        </el-col>
        <el-col :span="12" class="MyRightBox">
            <TitleHeader msg="我的账户"> </TitleHeader>
            <el-col :span="8" class="balance text-center">
              <span class="balanceNum">{{ info.balance }}</span>
              <h3>预存款 (元)</h3>
              <el-col class="action"
                ><span @click="jumpRecharge">充值</span><span>|</span
                ><span @click="jumpTransferAccount">转账</span></el-col
              >
            </el-col>
    <!-- 积分 start-->
            <el-col :span="8" class="balance text-center">
              <span class="balanceNum">{{
                PointsNum>0 ? PointsNum : 0
              }}</span>
              <h3>积分</h3>
              <el-col class="action">
                <span @click="jumpPointsList">查看</span>
                <span>|</span>
                <span @click="jumpPointsRules">规则</span>
              </el-col>
            </el-col>
    <!-- 积分 end-->
            <el-col :span="8" class="text-center balance">
              <span class="balanceNum">{{
                couponNum ? couponNum : 0
              }}</span>
              <h3>优惠券(张)</h3>
              <el-col class="action">
                <span @click="jumpCoupon">查看</span><span>|</span><span @click="jumpGetCoupon">领取</span>
              </el-col>
            </el-col>
          </el-col>
      </el-col>
    </el-row>
    <el-row class="myOrder">
      <TitleHeader msg="我的订单"> </TitleHeader>
      <el-row class="orderStatus">
        <div v-for="(item, index) in imgList" :key="index">
          <router-link
            tag="div"
            :to="{ name: `${item.link}`, params: { type: `${item.type}` } }"
          >
            <el-badge :value="item.num" class="badge text-center">
              <img v-if="index===0" src="@/assets/member_icon1.png" alt="0"/>
              <img v-if="index===1" src="@/assets/member_icon2.png" alt="1"/>
              <img v-if="index===2" src="@/assets/member_icon3.png" alt="2"/>
              <img v-if="index===3" src="@/assets/member_icon4.png" alt="3"/>
              <img v-if="index===4" src="@/assets/member_icon5.png" alt="4"/>
              <div class="text-center">{{ item.name }}</div>
            </el-badge>
          </router-link>
        </div>
      </el-row>
      <el-row class="logistics">
        <h2><img src="~assets/logistics.png" alt="" /><span>最新物流</span></h2>
        <el-col class="wraper">
          <el-col>
            <el-col :span="18">{{ info.enterpriseName }}</el-col>
          </el-col>
          <!-- 物流信息 -->
          <el-col :span="24" v-if="obj.routesArray">
            <el-col v-for="(item, index) in obj.routesArray.slice(-1)" :key="index">
              <el-col :span="24">
                <el-col :span="8" v-if="item.mailNo"
                  >运单号：{{ item.mailNo }}</el-col
                >
                <el-col class="logisticState" :span="8" v-if="item.sendState"
                  >{{ item.sendState }}</el-col
                >
              </el-col>
              <el-col :span="24">
                <el-col :span="12" v-if="item.acceptAddress">
                  收货地址：{{ item.acceptAddress }}
                </el-col>
              </el-col>
              <el-col :span="24" class="Newdetail">
                <el-col :span="8" v-if="item.acceptTime">
                  {{ item.acceptTime }}
                </el-col>
                <el-col  class="info" :span="14" v-if="item.remark">{{ item.remark }}</el-col>
              </el-col>
            </el-col>
          </el-col>
        </el-col>
      </el-row>
    </el-row>


    <el-dialog
        title="积分规则"
        :visible.sync="dialogVisible"
        width="60%"
        center
        >
        <IntegralRule></IntegralRule>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="dialogVisible = false">关  闭</el-button>
        </span>
      </el-dialog>
      <el-dialog class="AlertBox" :visible.sync="AlertDialogVisible" width="20%">
          
        <!-- 平台会员   有等级 -->
        <!-- <img v-if="AlertFlag==1" width="100%" src="@/assets/AssociatedDrugFirms/PlatformMember.png" alt=""/> -->
        <!-- 签约会员  无等级-->
        <!-- <img v-if="AlertFlag==2" width="100%" src="@/assets/AssociatedDrugFirms/ContractedMember.png" alt=""/> -->
        <!-- 权益升级 -->
        <!-- <img   width="100%" src="@/assets/AssociatedDrugFirms/levelAlert_V3.gif" alt=""/> -->
        <img  v-if="AlertFlag==3" width="100%" :src="AlertlevelBg" alt=""/>
        <!-- 会员等级已更新   季度更新弹出-->
        <!-- <img v-if="AlertFlag==4" width="100%" src="@/assets/AssociatedDrugFirms/UpdateLevel.png" alt=""/> -->

      </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { MemberInfo, Getnewest } from "api/member.js";
import { PostorderCount } from "api/order.js";
import {CouponNum,userPointsNum} from "api/member.js";


import v1 from 'assets/AssociatedDrugFirms/levelAlert_V1.gif';
import v2 from 'assets/AssociatedDrugFirms/levelAlert_V2.gif';
import v3 from 'assets/AssociatedDrugFirms/levelAlert_V3.gif';
import v4 from 'assets/AssociatedDrugFirms/levelAlert_V4.gif';
import v5 from 'assets/AssociatedDrugFirms/levelAlert_V5.gif';
import v6 from 'assets/AssociatedDrugFirms/levelAlert_V6.gif';
import v7 from 'assets/AssociatedDrugFirms/levelAlert_V7.gif';
import v8 from 'assets/AssociatedDrugFirms/levelAlert_V8.gif';
// import Qs from 'qs'
const TitleHeader = () => import("components/common/TitleHeader.vue");
const IntegralRule = () => import("views/IntegralRule.vue")
export default {
  name: "MyAccount",
  data() {
    return {
      AlertFlag:0,
      V1:v1,
      V2:v2,
      V3:v3,
      V4:v4,
      V5:v5,
      V6:v6,
      V7:v7,
      V8:v8,
      memberLevelName:'',
      AlertlevelBg:'',
      AlertDialogVisible: false, //初始弹框不出现
      dialogVisible:false,
      PointsNum:0,
      couponNum:0,
      info: {},
      imgList: [
        {
          name: "待付款",
          num: 0,
          type: 1,
          link: "MyOrder",
        },
        {
          name: "待配送",
          num:0,
          type: 2,
          link: "MyOrder",
        },
        {
          name: "待收货",
          num:0,
          type: 3,
          link: "MyOrder",
        },
        {
          name: "退款/售后",
          num: 0,
          type: 4,
          link: "AfterRefund",
        },
        {
          name: "全部订单",
          num:0,
          type: 0,
          link: "MyOrder",
        },
      ],
      obj: {
       
      },
    };
  },
  watch:{
    
  },
  methods: {
    goCode(value){
       this.$emit('getCode',value)
    },
    getMemberInfo: function() {
       this.goCode(1)
      // 获取积分数量
      userPointsNum().then(res=>{
        this.PointsNum=res.data.data.points
      })
      // 获取优惠券数量
      CouponNum().then(res=>{
        this.couponNum=res.data.data
      })
      // 会员信息
      MemberInfo().then((res) => {
        // const productData = Qs.parse(Qs.stringify(res.data)).data[0];
        this.info = res.data.data;
        // growthValue memberStoreLevel
        // console.log('9999999999成长值',this.info.growthValue)

        if(this.info.memberStoreLevel=='V1'){
          this.AlertlevelBg=this.V1
          this.memberLevelName='普通会员'
        }else if(this.info.memberStoreLevel=='V2'){
          this.AlertlevelBg=this.V2
          this.memberLevelName='标准会员'
        }else if(this.info.memberStoreLevel=='V3'){
          this.AlertlevelBg=this.V3
          this.memberLevelName='VIP会员'
        }else if(this.info.memberStoreLevel=='V4'){
          this.AlertlevelBg=this.V4
          this.memberLevelName='五星会员'
        }else if(this.info.memberStoreLevel=='V5'){
          this.AlertlevelBg=this.V5
          this.memberLevelName='金牌会员'
        }else if(this.info.memberStoreLevel=='V6'){
          this.AlertlevelBg=this.V6
          this.memberLevelName='钻石会员'
        }else if(this.info.memberStoreLevel=='V7'){
          this.AlertlevelBg=this.V7
          this.memberLevelName='超级会员'
        }else{
          this.AlertlevelBg=this.V8
          this.memberLevelName='至尊会员'
        }

          console.log(localStorage.getItem('memberLevel')==null)
         
          // memberStoreType//会员类型：1平台，2签约
                // if(this.info.memberStoreType==1){
                //   // 1平台
                //   this.AlertFlag=1
                // }else if(this.info.memberStoreType==2){
                //   // 2签约
                //   this.AlertFlag=2
                // }   
          if(this.info.memberStoreType==1){
             // 1平台
            if(localStorage.getItem('memberLevel')!=this.info.memberStoreLevel){
              //  此刻说明会员等级有变化
              this.AlertDialogVisible=true        
              this.AlertFlag=3
              localStorage.setItem('memberLevel',this.info.memberStoreLevel);
            }
          }

      });
      // 最新物流
      Getnewest().then((res) => {
        let code=res.data.code
        console.log(res.data)
        if(res.data.msg!=="暂无承载数据"){
           if(code!==400){
             if(res.data.data.logiInformation!=''){
                 const routesArray = JSON.parse(res.data.data.logiInformation);
                 this.$set(this.obj, "routesArray", routesArray);
             }
           }
         
        }
        
      });
      // 订单数量
      PostorderCount().then((res) => {
        this.imgList[0].num=res.data.data.toBePaid      //待付款
        this.imgList[1].num=res.data.data.paided        //待配送
        this.imgList[2].num=res.data.data.toBeDelivery  //待收货
        this.imgList[3].num=res.data.data.refundCount   //退款/售后
        this.imgList[4].num=res.data.data.orderTotal    //全部订单
      });
    },
    jumpMyOrder() {
      this.$router.push({ name: "MyOrder" });
    },
    jumpBalance() {
      this.$router.push({ name: "Balance" });
    },

//跳转积分明细列表
jumpPointsList(){
  this.$router.push({ name: "Points" });
},

//跳转积分规则
jumpPointsRules(){
  this.dialogVisible = true
},


JumpMemberLevel(){
  this.$router.push({ name: "MemberLevel" });
},

    jumpRecharge() {
      this.$router.push({ name: "Recharge" });
    },
    jumpTransferAccount() {
      this.$router.push({ name: "TransferAccount" });
    },
    jumpCoupon() {
      this.$router.push({ name: "Coupon" });
    },
    jumpGetCoupon(){
       this.$router.push({ name: "GetCoupon" });
    }
  },
  created() {
    this.getMemberInfo();
   
  },
  computed: {
    ...mapState({
      userName: (state) => state.userName,
    }),
  },
  components: {
    TitleHeader,
    IntegralRule

  },
};
</script>
<style lang="less" scoped>
@import "~style/index.less";
/deep/.AlertBox {
  padding: 0;
  .el-dialog {
      border-radius: 10px;
      background-color: transparent;
      box-shadow:none;
      // box-shadow: 0 1px 3px rgba(0,0,0,.3);
    }
    .el-dialog__header {
    padding: 0;

    .el-dialog__headerbtn {
        width: 100%;
        height: 100%;
        font-weight: bold;
        left: 0;
        opacity: 0;
        top: 0;
      // background: #fff;
      // color: #908181;
      // width: 30px;
      // height: 30px;
      // font-weight: bold;
      // border-radius: 50%;
      // right: -30px;
      // top: -15px;
      .el-dialog__close {
        font-weight: bolder;
        color: #908181;
        line-height: 30px;
        font-size: 24px;
      }
    }
  }
  .el-dialog__body {
    padding: 0;
    border-radius: 10px;

    img {
      border-radius: 10px;
    }
  }
}
.myAcount {
  .fonts();
  color: #666;
  padding: 0px;
  .list {
    cursor: pointer;
    padding:20px;
    font-weight: bold;
    background: url("~assets/myAccountBg.png") no-repeat;
    background-size:100% 100%;
    // padding-bottom: 20px;
    color: #5E2705;
    border-right:10px solid#F6F6F6;
    .levelBg {
      display: inline-block;
      width:94px;
      height: 18px;
      background: url("~assets/levelMsgBg.png") no-repeat;
      background-size:100% 100%;
      color: #d5c1ad;
      font-size:12px;
      line-height:16px;
      padding: 4px;
      text-indent:23px;
    }
    .GrownDiv{
      margin:20px 0;
    }

    .GrownNum{
      padding: 0px 16px;
      border-radius: 5px;
      border: 1px solid #7b7474;
      text-align: center;
      margin-left: 13px;
    }
    .el-col {
      padding-top: 15px;
    }
    .acount {
      span {
        vertical-align: top;
      }

      .name {
        display: inline-block;
        width: 360px;
        color: #5E2705;
      }
    }
    .service {
      color: #5E2705;
    }
    .person {
      padding-left: 10px;
      color: #5E2705;
    }
    .el-col:first-child {
      padding-top: 0px;
    }
  }
  /deep/.el-main{
    padding:0px!important;
  }
  .MyRightBox{
    padding:20px;
    background: #fff;
  }
  .balance {
    padding-top: 10px;
    .balanceNum {
      color: #333;
      font-size: 24px;
      line-height: 33px;
      font-weight: bold;
    }
    h3 {
      padding-top: 16px;
      font-size: 12px;
      color: #666;
      line-height: 17px;
    }
    .action {
      padding-top: 16px;
      span {
        color: #597ef7;
        padding-left: 10px;
         cursor: pointer;
            &:hover{
                color: #ff3333;
            }
      }
      span:nth-child(2) {
        color: #ccc;
         cursor: pointer;
            &:hover{
                color: #ff3333;
            }
      }
    }
  }
}
.myOrder {
  background: #fff;
  padding:20px;
  margin-top: 20px;
  .orderStatus {
    padding: 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      display: inline-block;
      width:42px;
      height:42px;
    }
    .badge {
      margin-top: 20px;
      margin-right: 40px;
       cursor: pointer;
            &:hover{
                color: #ff3333;
            }
    }
    .el-badge__content.is-fixed {
      top: 12px;
      right: 14px;
    }
  }
}

.logistics {
  color: #333;
  font-size: 14px;
  line-height: 20px;
  background: #f6f6f6;
  padding: 20px;
  border-radius: 5px;
  .logisticState {
    color: #ff3333;
  }
  h2 {
    padding-top: 10px;
    font-size: 16px;
    padding-bottom: 10px;
    img {
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: top;
    }
    span {
      padding-left: 10px;
    }
  }
  & > div {
    padding-top: 10px;
  }
  .info {
    display: flex;
    align-items: center;
  }
  .wraper {
    padding-top: 12px;
    padding-left: 30px;
    text-indent: 20px;
    & > div {
      padding-top: 10px;
    }
    .Newdetail {
      padding:5px 0 10px 0;
      margin-bottom: 10px;
      // border-bottom:1px solid #ccc ;
    }
  }
}
</style>
